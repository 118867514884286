<template>
  <div>
    <Loader v-if="orderWatchlist.length == 0" />
    <div class="dashboard__container--body" v-if="orderWatchlist.length > 0">
      <vue-good-table
            :columns="columns"
            :rows="orderWatchlist"
             styleClass="vgt-table condensed"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 50,
            }"
          >
          <template slot="table-column" slot-scope="props">
             <span v-if="props.column.label =='Pipeline'">
                <i class="fa-light fa-filter"></i>
             </span>
             <!-- <span v-else-if="props.column.field == 'watchlist'">
              <div style="width: 100%;" class="text-center">
                <i class="fa-regular fa-star"></i>
              </div>
            </span> -->
             <span v-else>
                {{props.column.label}}
             </span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'startDate'">
              <span v-if="props.row.startDate">{{props.row.startDate | moment("MM/DD/YYYY")}}</span>
            </span>
            <span v-else-if="props.column.field == 'visible'">
              <i class="fa-solid fa-check" v-if="props.row.visible" style="color:green;"></i>
            </span>
            <span v-else-if="props.column.field == 'title'">
              <router-link :to="`/orders/` + props.row.id">
                {{props.row.title}}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'pipeline'">
              <span v-if="props.row.pipeline && props.row.pipeline.length">
                {{props.row.pipeline.length}}
              </span>
            </span>
            <span v-else-if="props.column.field == 'client'">
             <!--  <span v-if="props.row.venue.client && props.row.venue.client[0]">
                {{props.row.venue.client[0].title}}
              </span> -->
              <span v-if="props.row.client && props.row.client[0]">
                {{props.row.client[0].title}}
              </span>
            </span>
            <span v-else-if="props.column.field == 'watchlist'">
              <button v-if="!isWatchList(props.row)" class="btn btn__icon" @click="addWatch(props.row)"><i class="fa-regular fa-star"></i></button>
               <button v-if="isWatchList(props.row)" class="btn btn__icon" @click="removeWatch(props.row)"><i class="fa-solid fa-star warning"></i></button>
            </span>
            <span v-else-if="props.column.field == 'groups'">
              <span v-if="!props.row.groups || props.row.groups.length == 0">
                <i class="fa-solid fa-user-group ml-2 mr-2" style="opacity:50%;"></i>
              </span>
              <span v-if="props.row.groups && props.row.groups.length > 0">
                <v-popover>
                  <i class="fa-solid fa-user-group ml-2 mr-2 blueHue"></i>
                  <template slot="popover">
                    <span v-for="z in props.row.groups">{{z}} / </span>
                  </template>
                </v-popover>
              </span>
              <!-- <span v-if="props.row.groups && props.row.groups.length >= 1">
                <button class="chipDark mb-1" v-for="(item, index) in props.row.groups" :key="index">{{item.title}}</button>
              </span> -->
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
      
    </div>  
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import ExportService from "@/services/ExportService"
import router from '@/router'

export default {
  name: 'orderWatchlist',
  data() {
    return {
      columns: [
      {
        label: 'Start Date',
        field: 'startDate',
         width: '88px',
        sortable: false,
      },
      {
        label: 'Watch',
        field: 'watchlist',
        tdClass: 'text-center-all',
        width: '48px',
        sortable: false,
      },
      {
        label: 'Pipeline',
        field: 'pipeline',
        width: '32px',
        sortable: false,
      },
      {
        label: 'Title',
        field: 'title',
         width: '160px',
        sortable: false,
      },
      {
        label: 'Type',
        field: 'shiftType',
        width: '60px',
        sortable: false,
      },
      {
        label: 'Order #',
        field: 'jobNum',
        width: '60px'
      },
      {
        label: 'PO/Ref',
        field: 'refNum',
        width: '60px'
      },
      {
        label: 'Client',
        field: 'client',
         width: '180px',
         sortable: false,
      },
      {
        label: 'Background',
        field: 'backgroundCheck',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Drug',
        field: 'drugScreening',
        width: '100px',
        sortable: false,
      },
      {
        label: 'Hours',
        field: 'glanceHours',
        width: '80px',
        width: '120px',
        sortable: false,
      },
      {
        label: 'Rate',
        field: 'glanceRate',
        width: '80px',
        sortable: false,
      },
      {
        label: 'Groups',
        field: 'groups',
        width: '60px',
        sortable: false,
      },
      {
        label: 'City',
        field: 'event_city',
         width: '80px'
      },
      {
        label: 'State',
        field: 'event_state',
         width: '60px'
      },
      {
        label: 'Jobsite',
        field: 'venue_title',
         width: '180px'
      },
      
      // {
      //   label: 'Published',
      //   field: 'visible',
      // },
    ],
    };
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'orderWatchlist']),
  },
  components: {
    Loader,
  },
  created () {
    if (!this.orderWatchlist || this.orderWatchlist.length < 1) {
      this.$store.dispatch("getOrderWatchlist")
    }
  },
  methods: {
    isWatchList(row) {
      if (this.userProfile && this.userProfile.orderWatchList && this.userProfile.orderWatchList.includes(row.id)) {
        return true
      } else {
        return false
      }
    },
    async removeWatch(row) {
      await this.$store.dispatch('unWatchOrder', row.id)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
  },
  beforeDestroy() {
    this.columns = null
    delete this.columns
  },
}
</script>